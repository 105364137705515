<template>

  <div>
        <div>
            <template>
                <v-toolbar flat>
                    <v-btn :to="{name: 'Competitions'}"
                        class="mr-2 "
                        color="primary"
                        plain
                    ><v-icon color="primary"  >mdi-chevron-left</v-icon><span>{{ $t('Competitions') }}</span></v-btn>
                    <v-toolbar-title v-if="data.name!=undefind">{{data.emoji+' '+data.name}}<span class="day-left">({{getDaysleft(data.endDate)}})</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
        </div>
        <div class="mt-4 ml-6 mb-20 overview-section">
            <v-row v-for="(userResult,index) in data.userResults" v-bind:key="index" class="user-progress">
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                >
                    <div class="user-name">
                        <v-avatar color="primary" class="" size="25" >
                            <v-img :src="$urlPrefix+'/img/profile-pictures/'+userResult.userId+'.png'" class="" alt="UD">
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    {{getUserNameText(userResult.userId)}}
                                    </v-row>
                                </template>
                            </v-img>
                        </v-avatar>
                        {{getUser(userResult.userId)}}
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="9"
                    md="9"
                >
                    <div class="d-flex">
                        <p class="mr-2">0</p>
                        <cprogress-bar
                            :percentage="userResult.result > data.goal ? 100 : (userResult.result/data.goal)*100"
                            :label="(index==0?'🥇':index==1?'🥈':index==2?'🥉':'')+userResult.result"
                            :class="index==0?'first-place':index==1?'second-place':index==2?'third-place':''"
                        ></cprogress-bar>
                        <p class="ml-2">{{data.goal}}</p>
                    </div>
                </v-col>

            </v-row>
        </div>
    </div>
</template>

<script>
    export default {
        components:{
            'cprogress-bar': {
                template: `
                <div class="cprogress-bar">
                    <div class="background-bar"></div>
                    <transition appear @before-appear="beforeEnter" @after-appear="enter">
                        <div class="tracker-bar">
                            <div class="info">
                                <label>{{label}}</label>
                            </div>
                        </div>
                    </transition>

                </div>`,
                props: {
                    percentage: Number,
                    label: Number|String,
                },
                methods: {
                    beforeEnter (el) {
                        el.style.width = 0
                    },
                    enter (el) {
                        el.style.width = `${this.percentage}%`
                        el.style.transition = `width 1s linear`
                    }
                }
            },
        },
        data(){
            return{
                data:[],
                users:[],
            }
        },
        watch: {

        },
        mounted ()
        {
        },
        created: function()
        {
            this.fetchUsers();
            if(this.$route.query.id){
                this.id = this.$route.query.id
            }
            this.getdata()
        },
        methods: {
            getDaysleft(endDate){
                var dayleft = 0;
                var start = new Date();
                var end = new Date(endDate);
                dayleft = (end - start) / 1000 / 60 / 60 / 24;
                dayleft = dayleft - (end.getTimezoneOffset() - start.getTimezoneOffset()) / (60 * 24);
                dayleft = Math.round(dayleft);
                if(dayleft < 1){
                    return this.$t("Competition Completed")
                }else if(dayleft==1){
                    return dayleft + " " + this.$t('day left')
                }else{
                    return dayleft + " " + this.$t('days left')
                }
            },
            getUserNameText(userid){
                let filterUser = this.users.filter(function(user){
                    return user.id == userid
                })
                if(filterUser[0]!=undefined){
                    return filterUser[0].firstName.charAt(0)+(filterUser[0].lastName!=null?filterUser[0].lastName.charAt(0):'');
                }else{
                    return '';
                }
            },
            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:'';
            },
            fetchUsers()
            {
                let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=true';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=true';
                    this.axios.get(uri2).then((response) => {
                        this.users = response.data.filter(function(user){
                            return user.role == 1||user.role == 2;
                        });
                    });
                });
            },
            getdata(){
                let uri = this.$urlPrefix + '/Competition/Overview?id='+this.id;
                this.axios.get(uri).then((response) => {
                    if(response.status == 200){
                        this.data = response.data;
                        this.data.userResults.sort((a, b) => (a.result < b.result) ? 1 : -1);
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
    $greydark: #c0cac9;
    $black: #333;
    $screen: #eff3f4;
    $pink:  #ff298a;
    $grey: #dedfe0;

    .active {
        label {
            color: $pink !important;
        }
        .tracker-bar {
            background: $pink !important;
        }
    }
    .cprogress-bar {
        width: 100%;
        label {
            color: white;
            float: right;
            margin-right:5px ;
        }
        .info {
            font-size: 15px;
            display: block;
            color: white;
            text-transform: uppercase;
            background-color: transparent !important;
            .percentage {
                font-weight: bolder;
            }
        }
        .background-bar {
            background: #1867c047 !important;
            width: 100%;
            height: 22px;
        }

        .tracker-bar {
            background: #0050ff !important;
            height: 22px;
            width: 0;
            transition: width 0.5s linear;
            margin-top: -22px;
        }
    }
    .cprogress-bar.first-place .tracker-bar {
        background: #CFB643 !important;
    }
    .cprogress-bar.second-place .tracker-bar {
        background: #A19DAB !important;
    }
    .cprogress-bar.third-place .tracker-bar {
        background: #976F59 !important;
    }
    span.day-left {
        margin-left: 10px;
        font-size: 12px;
        opacity: 0.6;
    }
    .overview-section{
        margin-bottom: 100px;
    }
</style>
